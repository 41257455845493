import React from 'react';

import './_projects.scss';

export const ProjectsPage = () => {
  const x = 'ProjectsPage';
  return (
    <>
      <h1>{x}</h1>
    </>
  );
};
